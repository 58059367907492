<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<PreloaderComponent :enable=enable></PreloaderComponent>
<v-container fluid v-if='enable'>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Filtres
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="row">
          <div class="col-md-2"><v-select
                  :items="users"
                  item-value=id
                  item-text=full_name
                  placeholder="Agent"
                  v-model=user_id
                ></v-select></div>
          <div class="col-md-2"><v-select
                  :items="statuses"
                  item-value=id
                  item-text=label
                  placeholder="Statut"
                  v-model=status
                ></v-select></div>
                <div class="col-md-2">
            <input v-model="sale_number" type='text'  placeholder="# de Vente" class='form-control'>
          </div>
          <div class="col-md-1"><v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=filter
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-check-bold</v-icon>
          </v-btn></div>
          <div class="col-md-1"><v-btn
            color="blue"
            text
            class = "reset_modal_button"
            v-on:click=reset
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn></div>
          <div class="col-md-1"><v-btn
            color="red"
            text
            class = "export_modal_button"
            v-on:click=exportPDF 
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn></div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
<v-card
  elevation="2" style="margin-top:20px"
>
<div class="row">
  <div class='col-md-12' style="padding-top:0px">
    <div class='table-responsive' style="max-height:500px;overflow-y:scroll">
        <table class="table table-striped table-hover tableFixHead" id="content" style="border-top:none">
          <thead>
            <tr>
              <th style="text-align:left">#</th>
              <th>MAGASIN</th>
              <th>AGENT</th>
              <th>CLIENT</th>

              <th v-if="filter_rate_id == 1">HTG</th>
              <th v-if="filter_rate_id == 2">USD</th>

              <th v-if="filter_rate_id == 3">HTG</th>
              <th v-if="filter_rate_id == 3">USD</th>

              <th>PRODUITS</th>
              <th>DATE</th>
              <th>HEURE</th>
              <th>STATUT</th>
              <th></th>
            </tr>
            <tr style="color:black;background:#dee2e6">
              <th colspan='4' style='text-align:left'>TOTAL ({{ rows.length }} FICHES)</th>
              <th v-if="filter_rate_id == 1">{{ total(1) | formatNumber }}</th>
              <th v-if="filter_rate_id == 2">{{ total(2) | formatNumber }}</th>

              <th v-if="filter_rate_id == 3">{{ total(3) | formatNumber }}</th>
              <th v-if="filter_rate_id == 3">{{ total(4) | formatNumber }}</th>

              <th>{{ products() }}</th>
              <th colspan='4'></th>

            </tr>
          </thead>
          <tbody style="margin-top:40px">
            
            <tr  v-for="sale in rows" :key="sale.id">
              <td style="text-align:left">{{ sale.sale_number }}</td>
              <td>{{ sale.store.name }}</td>
              <td>{{ sale.user.full_name }}</td>
              <td>{{ sale.customer.full_name }}</td>
              <td v-if="filter_rate_id == 1"><span v-if="sale.rate_id == 1">{{ sale.total | formatNumber}}</span> <span v-if="sale.rate_id == 2">{{ sale.total*sale.daily_rate | formatNumber }}</span></td>
              <td v-if="filter_rate_id == 2"><span v-if="sale.rate_id == 2">{{ sale.total | formatNumber }}</span> <span v-if="sale.rate_id == 1">{{ sale.total/sale.daily_rate | formatNumber }}</span></td>

              <td v-if="filter_rate_id == 3"><span v-if="sale.rate_id == 1">{{ sale.total | formatNumber }}</span></td>
              <td  v-if="filter_rate_id == 3"><span v-if="sale.rate_id == 2">{{ sale.total | formatNumber }}</span></td>


              <td><span class='badge badge-info'>{{ detailProducts(sale.sale_products) }}</span></td>
              <td>{{ sale.created_at | formatDate }}</td>
              <td>{{ sale.created_at | formatTime }}</td>
              <td v-if="sale.status == 1"><span class='badge badge-success'>Actif</span></td>
              <td v-if="sale.status == 0"><span class='badge badge-danger'>Annulé</span></td>
              <td style="text-align:right"><v-icon
        small
        class="mr-2 view_button"
        @click="view(sale.id)"
      >
        mdi-eye-outline
      </v-icon></td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</div>
</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import { jsPDF } from "jspdf"
import "jspdf-autotable"

export default {
  name: 'SalesReportView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/sales?orderdesc=created_at&include=user,store,customer,sale_products&from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        }).catch(function(error){
          console.log(error)
        })
    },
    detailProducts: function(products){
      var total = 0
      for (var j =  products.length - 1; j >= 0; j--) {
          total = total + parseInt(products[j].quantity)
      }

      return total
    },
    view: function(id){
      this.$router.push('/sales/'+id)
    },
    reset: function(){
      this.store_id = ''
      this.sale_number = ''
      this.user_id = ''
      this.status = ''

      this.filter()
    },
    total: function(rate_id){
      var total = 0;

      if(rate_id == 1){
        for (var i =  this.rows.length - 1; i >= 0; i--) {
          if(this.rows[i].status == 1){
            if(this.rows[i].rate_id == rate_id){
              total = total + parseFloat(this.rows[i].total)
            }else{
              total = total + parseFloat(this.rows[i].total) * parseFloat(this.rows[i].daily_rate)
            }
          }
        }
      }else if(rate_id == 2){
        for (i =  this.rows.length - 1; i >= 0; i--) {
          if(this.rows[i].status == 1){
            if(this.rows[i].rate_id == rate_id){
              total = total + parseFloat(this.rows[i].total)
            }else{
              total = total + parseFloat(this.rows[i].total) / parseFloat(this.rows[i].daily_rate)
            }
          }
        }
      }else if(rate_id == 3){
        for (i =  this.rows.length - 1; i >= 0; i--) {
          if(this.rows[i].status == 1){
            if(this.rows[i].rate_id == 1){
              total = total + parseFloat(this.rows[i].total)
            }
          }
        }
      }else{
        for (i =  this.rows.length - 1; i >= 0; i--) {
          if(this.rows[i].status == 1){
            if(this.rows[i].rate_id == 2){
              total = total + parseFloat(this.rows[i].total)
            }
          }
        }
      }
      
      return total
    },
    filter: function(){
      var from = localStorage.debut_periode
      var to = localStorage.fin_periode
      var params = '&from='+from.toLocaleString()+'&to='+to.toLocaleString()
      if(this.store_id){
        params = params + '&store_id='+this.store_id
      }

      if(this.sale_number){
        params = params + '&sale_number='+this.sale_number
      }

      if(this.user_id){
        params = params + '&user_id='+this.user_id
      }

      if(this.status == 0 || this.status == 1){
        params = params + '&status='+this.status
      }
      var self = this;
      this.enable = false
      this.$axios.get('/sales?orderdesc=created_at&include=user,store,customer,sale_products'+params)
      .then(function (response) {
        self.rows = response.data
        self.enable = true
      }).catch(function(error){
        console.log(error)
      })
    },
    exportPDF: function(){
      const doc = new jsPDF('l', 'pt')
      doc.autoTable({ html: '#content', theme: 'grid' })
      doc.save("rapport_ventes.pdf");
    },
    products: function(){
      var products = 0;
      for (var i =  this.rows.length - 1; i >= 0; i--) {
        if(this.rows[i].status == 1){
          for (var j =  this.rows[i].sale_products.length - 1; j >= 0; j--) {
              products = products + parseFloat(this.rows[i].sale_products[j].quantity)
          }
        }
        
      }
      return products
    },
    getStores: async function(){
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      await this.$axios.get('/stores?orderasc=name&business_id='+this.business_id)
        .then(function (response) {
          self.stores = response.data
        })
    },
    getUsers: async function(){
      var self = this;
      await this.$axios.get('/users')
        .then(function (response) {
          self.users = response.data
        })
    },
    
  },
  created(){
    this.filter_rate_id = localStorage.filter_rate_id
    this.load()
    this.getUsers()
    this.getStores()
    
  },
  data: () => ({
      items: [
        {
          text: 'RAPPORT VENTES',
          disabled: false,
          href: '/',
        }
      ],
      filter_rate_id: 1,
      rows: [],
      stores: [],
      enable: false,
      loading: false,
      fluid: true,
      store_id:'',
      sale_number:'',
      business_id : 0,
      user_id:'',
      users: [],
      status: '',
      statuses: [{"id" : 1, 'label' : 'Actif'}, {"id" : 0, 'label' : 'Annulé'}]
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  td{
    font-size: 12px!important;
  }
  th{
    font-size: 12px!important;
  }
  td,th{
    text-align: center;
  }
  .v-select__slot{
    border: 1px solid #ced4da;
    padding: 4px 12px;
    border-radius: 4px;
  }
  .v-text-field > .v-input__control > .v-input__slot:before{
    display: none;
  }
  .v-application .v-btn{
    width: 100%!important;
  }
  .v-text-field{
    margin-top: 0px!important;
    padding-top: 0px!important;
  }
  .v-breadcrumbs__item{
    color: black!important;
  }
  .v-text-field__details{
    display: none!important;
  }
  .v-input__slot{
    margin-bottom: 0px!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
    text-align: center; 
    height:41px!important;
    margin-top:0px;
  }

  .v-btn.reset_modal_button{
    background-color:#1867c0 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .v-btn.export_modal_button{
    background: #F44336 !important;
    color:white!important;
    height:41px!important;
    margin-top:0px;
  }
  .form-control {
    height: auto!important; 
    padding: 0.45rem 0.75rem!important;
}
.view_button{
    font-size: 16px;
    padding: 4px 4px 4px 4px;
    background: #fb8c00 !important;
    color: white!important;
    border-radius: 4px;
  }
</style>